<template>
  <router-view />
</template>

<script>
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
// eslint-disable-next-line import/no-cycle
import phyPropertiesModule from '@/store/main/catalog/phy-properties'

export default {
  name: 'PhyProperties',
  created() {
    this.$emit('updateMenu', 'home-catalog-phy-properties')
  },
  setup() {
    const MODULE_NAME = 'phy-properties'
    if (!store.hasModule(MODULE_NAME)) store.registerModule(MODULE_NAME, phyPropertiesModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODULE_NAME)) store.unregisterModule(MODULE_NAME)
    })
  },
}
</script>

<style scoped>

</style>
