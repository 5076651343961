import crudActions from '@/libs/storeConstActions'
import store from '@/store'
import axios from '@/libs/axios'
// import axios from '@/libs/axios'

const endpoint = () => `products/${store.state.catalogs?.catalog?.id}/physical-properties`
const updatePhy = (ctx, data) => axios.put(endpoint(), data)

const getPhy = ({ commit }) => new Promise((resolve, reject) => {
  axios.get(endpoint())
    .then(response => {
      commit('GET', response.data.data)
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})
const Actions = crudActions(endpoint)

export default {
  ...Actions,
  updatePhy,
  getPhy,
}
