export default {
  GET(state, data) {
    state.phyProperties = { ...data }
  },
  LIST(state, data) {
    state.phyPropertieses = data
  },
  SET_PHY_PROPERTIES_FORM(state, data) {
    state.phyPropertiesForm = { ...data }
  },

  CHANGE_VALUE_BY_UNITE_TYPE(state, data) {
    const { oldUnitType, newUnitType } = data
    let factoryLength = 1
    let factoryWeight = 1
    if (oldUnitType && oldUnitType.id) {
      factoryLength /= oldUnitType.factor_length
      factoryWeight /= oldUnitType.factor_weight
    }
    if (newUnitType && newUnitType.id) {
      factoryLength *= newUnitType.factor_length
      factoryWeight *= newUnitType.factor_weight
    }
    const lengthList = [
      'width',
      'depth',
      'height',
      'inner_measurement_width',
      'inner_measurement_height',
      'inner_measurement_depth',
    ]
    const weightList = [
      'weight',
    ]
    lengthList.forEach(key => {
      if (state.phyPropertiesForm[key]) {
        state.phyPropertiesForm[key] = (state.phyPropertiesForm[key] / factoryLength).toFixed(3)
      }
    })

    weightList.forEach(key => {
      if (state.phyPropertiesForm[key]) {
        state.phyPropertiesForm[key] = (state.phyPropertiesForm[key] / factoryWeight).toFixed(3)
      }
    })
  },
}
